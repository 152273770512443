import React, { Component } from 'react';
import Grid from '../GridComponent/Grid';
import FlipCardContainer from '../FlipCardContainerComponent/FlipCardContainer';
import { store } from '../../config/fbConfig';
import { Container, Row, Col, Button, ButtonGroup } from 'reactstrap';


class Gigs extends Component {
    constructor(props) {
        super(props);
  
        this.state = {
            gigData: [],
            display: 'cards'
      }
    }

    componentDidMount() {
        this.getGigData()
      }
    
    getGigData() {
        var gigArray = [];
    
        store.collection('gigs').get().then((snapshot) => {
            snapshot.docs.forEach(doc => {
                gigArray.push(doc.data());
                return gigArray
            })
            this.setState({
                gigData: gigArray
            })
    
        })
    }

    onRadioBtnClick(e) {
        this.setState(state => ({
            display: e
        }))
      }
    
    

    render() {
        const queryString = this.props.location.search;
        const display = this.state.display;
        const gigData = this.state.gigData;
        let gigDisplay;

        if(display === 'cards') {
            gigDisplay = <FlipCardContainer gigData = {gigData} />;
        } else {
            gigDisplay = <Grid queryString={queryString} />;
        }

        return (
            <section className="main-area section-gap" id="home">
                <div className="container">
                    <Container style= {{justifyContent:'space-between'}}>
                        <Row>
                            <Col md="9">
                                <h2><i style={{paddingRight: 10}} className="fa fa-search"></i>Start Your Gig Search!</h2>
                            </Col>
                            <Col md="3" className="text-center text-md-right">
                                <ButtonGroup>
                                    <Button color="primary" onClick={() => this.onRadioBtnClick('cards')}>Cards</Button>
                                    <Button color="primary" onClick={() => this.onRadioBtnClick('grid')}>Grid</Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </Container>
					<br />
                    {gigDisplay}
                </div>
			</section>
        );
    }
}

export default Gigs;
