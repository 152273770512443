import React from 'react';
import { Container, Row } from 'reactstrap';
import FlipCard from '../FlipCardContainerComponent/FlipCard';
import ErrorBoundary from '../ErrorBoundaryComponent/ErrorBoundary';


export default class FlipCardContainer extends React.Component {
  
    render() {
      const gigs = renderGigs(this.props.gigData);
      return (
        <Container>
          <Row>
              { gigs }
          </Row>
        </Container>
      )
    }
  }

function renderGigs(gigs) {
    if (gigs.length > 0) { 

        return gigs.map((gig, index) => (
          <ErrorBoundary>
            <FlipCard key={index} gig={gig} />
          </ErrorBoundary>
        ));
    }
    else return [];
}