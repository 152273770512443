import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { app, googleProvider } from  '../../config/fbConfig'
import { NavLink } from 'react-router-dom';
import {
    Container, Col, Form,
    FormGroup, Input,
    Button, FormText, FormFeedback,
  } from 'reactstrap';
import {ToastContainer, toast } from 'react-toastify';


const loginStyles = {
    width: "90%",
    maxWidth: "315px",
    margin: "20px auto",
    border: "1px solid #ddd",
    borderRadius: "5px",
    padding: "10px"
}

class Login extends Component {
    constructor (props) {
        super(props)
        this.state = {
            redirect: false,
            'email': '',
            'password': '',
            validate: {
              emailState: '',
              passwordState: ''
            }
          }
        this.authWithGoogle = this.authWithGoogle.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    authWithGoogle() {
        app.auth().signInWithPopup(googleProvider)
        .then((user, error) => {
            if (error) {
                toast.error("Unable to sign in with Google",{
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            } else {
                this.props.setCurrentUser(user)
                this.setState({ redirect: true })
            }
        })
    }

    validateEmail(e) {
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const { validate } = this.state
          if (emailRex.test(e.target.value)) {
            validate.emailState = 'has-success'
          } else {
            validate.emailState = 'has-danger'
          }
          this.setState({ validate })
        }
    
    validatePasswordLength(e) {
        const { validate } = this.state
            if (e.target.value.length >= 6) {
            validate.passwordState = 'has-success'
            } else {
            validate.passwordState = 'has-danger'
            }
            this.setState({ validate })
        }

    handleChange = async (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        await this.setState({
          [ name ]: value,
        });
      }
    
    authWithEmailPassword(e) {
        e.preventDefault()

        const email = this.state.email
        const password = this.state.password

        console.log(this.state.validate.emailState)
        console.log(this.state.validate.passwordState)
        app.auth().fetchSignInMethodsForEmail(email)
            .then((providers) => { 
                if (providers.length === 0) {
                    // create user
                    return app.auth().createUserWithEmailAndPassword(email, password)
                } else if (providers.indexOf("password") === -1) {
                    // they used google
                    toast.warn( "Register with Google" );
                } else {
                    // sign user in
                    toast.warn( "Email: " + email + " is already registered" );
                }

            })
            .then((user) => {
                if (user && user.user.email) {
                    this.props.setCurrentUser(user)
                    this.setState({redirect: true})
                }
            })
            .catch((error) => {
                toast.error(error.message)
            })
    }

    render () {
        const { from } = this.props.location.state || { from: { pathname: '/' } }

        if (this.state.redirect === true) {
            return <Redirect to= { from } />
        }

        const { email, firstName, lastName, password } = this.state;
        return (
            <Container className="App" style={loginStyles}>
                <h2>Sign Up</h2>
                <Button color="primary" size="lg" block onClick={() => {this.authWithGoogle()}}>Sign up with Google</Button>
                <Form  onSubmit={ (e) => this.authWithEmailPassword(e) } >
                <Col>
                    <FormGroup>
                    <Input
                        type="email"
                        name="email"
                        value={ email }
                        placeholder="Email Address"
                        valid={ this.state.validate.emailState === 'has-success' }
                        invalid={ this.state.validate.emailState === 'has-danger' }
                        onChange={ (e) => {
                                    this.validateEmail(e)
                                    this.handleChange(e)
                                  } }
                    />
                    <FormFeedback valid>
                        That's a tasty looking email you've got there.
                    </FormFeedback>
                    <FormFeedback>
                        Uh oh! Looks like there is an issue with your email. Please input a correct email.
                    </FormFeedback>
                    <FormText>Your email address will be your username.</FormText>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                    <Input
                        type="text"
                        name="firstName"
                        value={ firstName }
                        placeholder="First Name"
                    />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                    <Input
                        type="text"
                        name="lastName"
                        value={ lastName }
                        placeholder="Last Name"
                    />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                    <Input
                        type="password"
                        name="password"
                        value={ password }
                        placeholder="Create a password"
                        valid={ this.state.validate.passwordState === 'has-success' }
                        invalid={ this.state.validate.passwordState === 'has-danger' }
                        onChange={ (e) => {
                            this.validatePasswordLength(e)
                            this.handleChange(e) } }
                    />
                    <FormFeedback valid>
                        At least 6 characters
                    </FormFeedback>
                    <FormFeedback>
                        X At least 6 characters
                    </FormFeedback>
                    </FormGroup>
                </Col>
                <Button>Register</Button>
                <Col>
                    <label> Already have an account? </label>
                    <NavLink to={{pathname: '/login'}}> Sign In </NavLink>
                </Col>
                </Form>
                <ToastContainer />
            </Container>
        )
    }
}

export default Login