import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { Card, CardImg, CardTitle, CardText,
  CardSubtitle, CardBody } from 'reactstrap';
import { MDBMask, MDBView, MDBRow, MDBCol } from "mdbreact";

class HomePage extends Component {

    render() {
        return (
          /*  Start main Area */
        	<div>
            {/*  Start Types of Gigs */}
            <Container>
              <Row style={{ paddingBottom: 20 }}>
                <Col>
            	    <h2>
            			    What Type of Gigs Are You Looking For?
            			</h2>
            		</Col>
              </Row>
              <MDBRow style={{paddingBottom: 40}}>
                <MDBCol md="4">
                  <MDBView hover zoom>
                    <Link to={{pathname: '/Gigs', search:'?workFromHomeFlag=true'}} >
                      <img src={require("../../img/WorkFromHome.jpg")} alt="work from home"/>
                      <MDBMask className="flex-center" overlay="red-strong">
                        <p className="white-text">Work From Home</p>
                      </MDBMask>
                    </Link>
                  </MDBView>
                </MDBCol>
                <MDBCol md="4">
                  <MDBView hover zoom>
                    <Link to={{pathname: '/Gigs', search:'?gigType=Sharing Economy'}} >
                      <img src={require("../../img/SharingEconomy.jpg")} alt="lyft and uber"/>
                      <MDBMask className="flex-center" overlay="red-strong">
                        <p className="white-text">Sharing Economy</p>
                      </MDBMask>
                    </Link>
                  </MDBView>
                </MDBCol>
                <MDBCol md="4">
                  <MDBView hover zoom>
                    <Link to={{pathname: '/Gigs', search:'?vehicleRequiredFlag=true'}} >
                      <img src={require("../../img/VehicleRequired.jpg")} alt="Tesla vehicle"/>
                      <MDBMask className="flex-center" overlay="red-strong">
                        <p className="white-text">Vehicle Required</p>
                      </MDBMask>
                    </Link>
                  </MDBView>
                </MDBCol>
              </MDBRow>
             {/*  Start Featured Gigs */}
              <Row style={{ paddingTop: 40, paddingBottom: 20}}>   
                <Col>  
                  <h2>
                    Featured Gigs
                    <i style={{paddingLeft: 10}} className="fa fa-trophy color:gold" aria-hidden="true"></i>
                  </h2>
                </Col>   
              </Row>
              <Row style={{paddingBottom: 40}}>
                  <Col md='3'>
                    <Card outline color="white">
                      <CardImg top width="100%" src={require("../../img/Spare5.png")} alt="Spare5 logo"/>
                      <CardBody>
                        <CardTitle><h3>Spare5</h3></CardTitle>
                        <CardSubtitle>Micro-Task</CardSubtitle>
                        <CardText> 
                          <span className="fa fa-star checked"></span>
                          <span className="fa fa-star checked"></span>
                          <span className="fa fa-star checked"></span>
                          <span className="fa fa-star checked"></span>
                          <span className="fa fa-star checked"> 5 </span></CardText>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md='3'>
                    <Card outline color="white">
                      <CardImg src={require("../../img/Turo.png")} alt="Turo logo"/>
                      <CardBody>
                        <CardTitle><h3>Turo</h3></CardTitle>
                        <CardSubtitle>Sharing Economy</CardSubtitle>
                        <CardText> 
                          <span className="fa fa-star checked"></span>
                          <span className="fa fa-star checked"></span>
                          <span className="fa fa-star checked"></span>
                          <span className="fa fa-star checked"></span>
                          <span className="fa fa-star checked"> 5 </span></CardText>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md='3'>
                    <Card outline color="white">
                      <CardImg src={require("../../img/Rover.png")} alt="Rover logo"/>
                      <CardBody>
                        <CardTitle><h3>Rover</h3></CardTitle>
                        <CardSubtitle>Animal Care</CardSubtitle>
                        <CardText> 
                          <span className="fa fa-star checked"></span>
                          <span className="fa fa-star checked"></span>
                          <span className="fa fa-star checked"></span>
                          <span className="fa fa-star checked"></span>
                          <span className="fa fa-star checked"> 4.9 </span></CardText>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md='3'>
                    <Card outline color="white">
                      <CardImg src={require("../../img/Airbnb.png")} alt="Airbnb logo"/>
                      <CardBody>
                        <CardTitle><h3>Airbnb</h3></CardTitle>
                        <CardSubtitle>Sharing Economy</CardSubtitle>
                        <CardText> 
                          <span className="fa fa-star checked"></span>
                          <span className="fa fa-star checked"></span>
                          <span className="fa fa-star checked"></span>
                          <span className="fa fa-star checked"></span>
                          <span className="fa fa-star checked"> 4.4 </span></CardText>
                      </CardBody>
                    </Card>
                  </Col>
              </Row>
              { /*  End Featured Gigs */}
            </Container>
          </div>
        );
    }
}

export default HomePage;
