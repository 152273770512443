import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { app } from '../../config/fbConfig';
import { Spinner } from 'reactstrap';

class Logout extends Component {
  constructor() {
    super()
    this.state = {
      redirect: false
    }
  }

  componentWillMount() {
    app.auth().signOut().then((user) => {
      this.setState({ redirect: true })
    })
  }

  render() {
    if (this.state.redirect === true) {
      return <Redirect to="/" />
    }

    return (
        <div style={{ textAlign: "center", position: "absolute", top: "25%", left: "50%" }}>
          <h3>See Ya Later!</h3>
          <Spinner style={{ width: '3rem', height: '3rem' }} type="grow" />
        </div>
    )
  }
}

export default Logout