import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';


 // Initialize Firebase
  const config = {
    apiKey: process.env.REACT_APP_GIGS_API_KEY,
    authDomain: process.env.REACT_APP_GIGS_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_GIGS_DATABASE_URL,
    projectId: process.env.REACT_APP_GIGS_PROJECT_ID,
    storageBucket: process.env.REACT_APP_GIGS_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_GIGS_MESSAGING_SENDER_ID
  };
  const app = firebase.initializeApp(config);
  const googleProvider = new firebase.auth.GoogleAuthProvider();
  const store = firebase.firestore();

export { app, store, googleProvider };