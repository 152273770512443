import React, { Component } from 'react';
import { NavLink as RRNavLink } from 'react-router-dom';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink} from 'reactstrap';

class Header extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    return (
      <div>
        <Navbar fixed="top" color="primary" className="navbar-dark" expand="md">
          <NavbarBrand href="/">Gig Site</NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            {
              this.props.authenticated
              ? (
                <Nav className="ml-auto" navbar>
                  <NavItem>
                    <NavLink tag={RRNavLink} style={{ color: '#FFF' }} to={{pathname: '/'}}>Home</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={RRNavLink} style={{ color: '#FFF' }} to={{pathname: '/Gigs'}}>Gigs</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={RRNavLink} style={{ color: '#FFF' }} to={{pathname: '/Logout'}} aria-label="Log Out">
                      <i className="fas fa-sign-out-alt" />
                    </NavLink>
                  </NavItem>
                </Nav>
              )
              : (
                <Nav className="ml-auto" navbar>
                  <NavItem>
                    <NavLink tag={RRNavLink} style={{ color: '#FFF' }} to={{pathname: '/'}}>Home</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={RRNavLink} style={{ color: '#FFF' }} to={{pathname: '/login'}}>Log in</NavLink>
                  </NavItem> 
                </Nav>
              )
            }
          </Collapse>
        </Navbar>
      </div>
    )
  }
}

export default Header;


