import React, { useLayoutEffect } from 'react';
import { Card, CardImgOverlay, CardTitle, CardText, CardImg, Col} from 'reactstrap';
import Flippy, { FrontSide, BackSide} from 'react-flippy';

export default class FlipCard extends React.Component {
    constructor() {
      super();
      this.state = {
      };
    }

    render() {
      const picture = this.props.gig.picture;
      const gigName = this.props.gig.gigName;
      const review = this.props.gig.review;
      const payRate = this.props.gig.payRate;


      return (
        <Col className="mb-4" md='3'>
         <Flippy
            flipOnHover={true} // default false
            flipOnClick={false} // default false
            flipDirection="horizontal" // horizontal or vertical
            ref={(r) => this.flippy = r} // to use toggle method like this.flippy.toggle()
            // if you pass isFlipped prop component will be controlled component.
            // and other props, which will go to div
            style={{ width: '200px', height: '200px' }} /// these are optional style, it is not necessary
          >
            <FrontSide>
              <CardImg top width="100%" src={require(`../../img/${picture}`)} alt="Card image cap" />
            </FrontSide>
            <BackSide>
              <CardImg style={{opacity:0.1}} src={require(`../../img/${picture}`)} alt="Card image cap" />
              <CardImgOverlay>
                <CardTitle>Gig: {gigName}</CardTitle>
                <CardText>Review: {review}</CardText>
                <CardText>Pay: ${payRate}</CardText>
              </CardImgOverlay>
            </BackSide>
          </Flippy>
        </Col>
      )
    }
  }



//   <ReactCardFlip isFlipped={this.state.isFlipped} flipDirection="vertical">
//   <Card style={{height:"100%"}} onMouseEnter={this.handleMouseEvent} key="front">
//       <CardImg top width="100%" src={require("../../img/"+ gigName +".png")} alt="Card image cap" />this.props.gig.picture
//       <CardImgOverlay>
//         <CardTitle></CardTitle>
//         <CardText></CardText>
//         <CardText></CardText>
//       </CardImgOverlay>
//   </Card>
//   <Card style={{height:"100%"}} onMouseLeave={this.handleMouseEvent} key="back">
//       <CardImg top width="100%" style={{opacity:0.2}} src={require("../../img/"+ gigName +".png")} alt="Card image cap" />
//       <CardImgOverlay>
//         <CardTitle>{gigName}</CardTitle>
//         <CardText>{review}</CardText>
//         <CardText>{payRate}</CardText>
//       </CardImgOverlay>
//   </Card>
// </ReactCardFlip>