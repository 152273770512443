import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route, Redirect } from 'react-router-dom';
import { app } from './config/fbConfig';
import { Spinner } from 'reactstrap';

// Components
import Header from './Components/HeaderComponent/Header';
import HomePage from './Components/Pages/HomePage';
import Gigs from './Components/Pages/Gigs';
import ReactGA from 'react-ga';
import Login from './Components/LoginComponent/Login';
import Register from './Components/LoginComponent/Register';
import Logout from './Components/LogoutComponent/Logout';

//Style
import './css/fontawesome-all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "mdbreact/dist/css/mdb.css";
import 'react-toastify/dist/ReactToastify.css';


function AuthenticatedRoute({component: Component, authenticated, ...rest}) {
  return (
    <Route
      {...rest}
      render={(props) => authenticated === true
          ? <Component {...props} {...rest} />
          : <Redirect to={{pathname: '/login', state: {from: props.location}}} /> } />
  )
}

class App extends Component {
  constructor() {
    super();
    this.setCurrentUser = this.setCurrentUser.bind(this);
    this.state = {
      authenticated: false,
      loading: true,
      currentUser: null
    };
  }

  initializeReactGA() {
    ReactGA.initialize('UA-135970450-1');
    ReactGA.pageview("/");
 }

  setCurrentUser(user) {

    if (user) {
      this.setState({
        currentUser: user,
        authenticated: true
      })
    } else {
      this.setState({
        currentUser: null,
        authenticated: false
      })
    }
  }

  componentWillMount() {
    this.removeAuthListener = app.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({
          authenticated: true,
          currentUser: user,
          loading: false
        })
      } else {
        this.setState({
          authenticated: false,
          currentUser: null,
          loading: false
        })
      }
    })
  }

  componentDidMount() {
    this.initializeReactGA()
  }

  componentWillUnmount() {
    this.removeAuthListener();
  }

  render() {
    if (this.state.loading === true) {
      return (
        <div style={{ textAlign: "center", position: "absolute", top: "25%", left: "50%" }}>
          <Spinner style={{ width: '3rem', height: '3rem' }} type="grow" />
        </div>
      )
    }
    return (
        <Router>
          <div className='App'>
            <Header authenticated={this.state.authenticated} />
            <div className="main-content" style={{padding: "5em"}}>
              <Route exact path="/login" render={(props) => {
                  return <Login setCurrentUser={this.setCurrentUser} {...props} />
                }} />
              <Route exact path="/register" render={(props) => {
                  return <Register setCurrentUser={this.setCurrentUser} {...props} />
                }} />
              <Route exact path="/logout" component={Logout} />
              <Route exact path='/' component={HomePage} />
              <AuthenticatedRoute path='/Gigs:id?' authenticated={this.state.authenticated} component={Gigs} />
            </div>
          </div>
        </Router>
    );
  }
}

export default App;
