import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-bootstrap.css';
import { Button } from 'reactstrap';
import { store } from '../../config/fbConfig';
import qs from 'query-string';

export default class Grid extends Component {
  constructor(props) {
      super(props);

      this.state = {
          columnDefs: [
            {headerName: "Gig", 
                field: "gigName"},

            {headerName: "Type", 
                field: "gigType"},

            {headerName: "Compensation ($/hr)", 
                field: "payRate",
                valueFormatter: currencyFormatter},

            {headerName: "Review", 
                field: "review",
                valueFormatter: reviewFormatter},

            {headerName: "Vehicle Required?", 
                field: "vehicleRequiredFlag",
                valueFormatter: trueFalse,
                filter: "agTextColumnFilter"},

            {headerName: "Work From Home?", 
                field: "workFromHomeFlag",
                valueFormatter: trueFalse,
                filter: "agTextColumnFilter"},
          ],
          rowData: [],
          defaultColDef: { sortable: true,
                            filter: true,
                            sortable:true,
                            resizable:true},
        }
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.setGridData();
    }

    setGridData() {
        var gigArray = [];

        store.collection('gigs').get().then((snapshot) => {
            snapshot.docs.forEach(doc => {
                gigArray.push(doc.data());
                return gigArray
            })
            this.setState({
                rowData: gigArray
            })
            this.initializeFilters();
        })
    }

    initializeFilters(){
        var filterPath = this.props.queryString
        console.log(filterPath)
        var parsedFilterObjects = qs.parse(filterPath)
        console.log(parsedFilterObjects)
        
        for(var field in parsedFilterObjects){
            var model = {[field]:{
                            type: "equals",
                            filter: parsedFilterObjects[field]
                        }};
            this.gridApi.setFilterModel(model);
            this.gridApi.onFilterChanged();
          }
    }

    clearFilters() {
        this.gridApi.setFilterModel(null);
        this.gridApi.onFilterChanged();
      }

    render() {
        return (
            <div>
                <div>
                    <span>
                        <Button onClick={this.clearFilters.bind(this)}
                                outline color="grey"
                                size="sm">
                            Clear Filters
                        </Button>{' '}
                    </span>
                </div> 
                <div
                    className="ag-theme-material"
                    style={{
                        //height: '100vh',
                        width: '100%' }}
                >
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        defaultColDef={this.state.defaultColDef}
                        rowData={this.state.rowData}
                        animateRows={true}
                        onGridReady={this.onGridReady.bind(this)}
                    >
                    </AgGridReact>
                </div>
            </div>
        );
    }
}

function reviewFormatter(params) {
    var value = params.value == null ? 
        "Be The First To Review" : params.value + " Stars"
    return value; 
  }

  function currencyFormatter(params) {
    var value = params.value == null ? 
        "No Compensation Data" : "$"+params.value
    return value; 
  }
  
  function trueFalse(params) {
    var value = params.value ? 
        "Yes": "No";
    return value;
  }













      // initializeFilters() { //Initialize Filter Based on URL Path
    //     var gridFilterPath = this.props.GridFilterPath.split('/')[2];
    //     var filterFunction = {
    //         WorkFromHome: this.workFromHomeFilter.bind(this),
    //         //SharingEconomy: this.sharingEconomyFilter.bind(this),
    //         //VehicleRequired: this.vehicleRequiredFilter.bind(this)
    //     }; 
    //     if (filterFunction.hasOwnProperty(gridFilterPath)) {
    //         filterFunction[gridFilterPath]();
    //     }
    // }

 //   workFromHomeFilter() {
 //           this.setFilter("workFromHomeFlag","equals",true)
/*         var model = {workFromHomeFlag: {type: "equals",filter: true}
            };
        this.gridApi.setFilterModel(model);
        this.gridApi.onFilterChanged(); */
      //}

    //  sharingEconomyFilter() {
    //     var model = {gigType: {
    //                     type: "equals",
    //                     filter: "Sharing Economy"
    //                 }};
    //     this.gridApi.setFilterModel(model);
    //     this.gridApi.onFilterChanged();
    //   }

    // vehicleRequiredFilter() {
    //     var model = {vehicleRequiredFlag: {
    //                     type: "equals",
    //                     filter: "true"
    //                 }};
    //     this.gridApi.setFilterModel(model);
    //     this.gridApi.onFilterChanged();
    //   } 